.layout-home {
  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .on-hover-carousel:hover {
    cursor: pointer;
  }

  img {
    align-content: center;
    margin: 0;
    padding: 0;
  }

  .p-card {
    padding: 0;
  }

  .p-card-body {
    padding: 5px;
  }

  .card-img-home {
    margin-bottom: 10px;
  }

  .p-card-title {
    font-size: 0.9rem;
    font-weight: normal;
    padding: 0;
    margin: 0;
    text-align: center;
    color: rgb(42, 145, 139);
  }

  .p-toast {
    position: absolute;
  }

  .p-card {
    height: 100%;
  }

  .title {
    color: rgb(42, 145, 139);
    font-size: 25px;
    float: left;
  }

  .p-card-content {
    font-size: 1.1rem;
    font-weight: 700;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }
}

.p-toolbar-group-right {
  .p-button {
    margin-left: 10px;
  }
}
