* {
  box-sizing: border-box;
  font-family: "Apis";
}

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  font-size: $fontSize;
  color: $textColor;
  background-color: $bodyBgColor;
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

.layout-wrapper {
  padding: 0;
  min-height: 100vh;
}

.padding-left-right {
  padding-left: 10vw;
  padding-right: 10vw;
}

.max-height {
  height: 100vh;
}

.flex-end {
  align-self: flex-end;
}
