.forms-layout {
  margin-top: 100px;
  margin-bottom: 100px;

  .margin-top-30 {
    margin-top: 30px;
  }

  .password-link {
    font-size: 0.9em;
  }

  .p-checkbox {
    margin-right: 5px;
  }

  .p-password {
    display: contents;
  }

  .p-panel-header {
    background-color: #3b97de;
    border: 4px solid #3b97de;
    color: white;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    font-size: 18px;
  }

  .registration-link {
    margin-left: 5px;
  }

  .p-panel {
    width: 70%;
  }

  .p-checkbox-label {
    display: contents;
  }

  .form-input {
    .p-inputtext {
      border: 1px solid $CURIOUS_BLUE;
      border-radius: 6px;
    }
    .p-inputtext:enabled:hover {
      border: 1px solid $CURIOUS_BLUE;
    }
  }

  .form-button {
    .p-button-text {
      font-size: 15px;
      font-weight: bold;
    }
    .p-button:enabled:hover {
      background-color: $LOCHINVAR;
      border-color: $LOCHINVAR;
    }
  }

  .panel-registration {
    font-family: "Apis";

    .p-button {
      background-color: $LOCHINVAR;
      border-color: $LOCHINVAR;
      border-radius: 8px;
    }

    .p-panel-content {
      min-height: 256px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border: 1px solid $CURIOUS_BLUE;
    }

    .p-panel-titlebar {
      font-size: large;
      background-color: $CURIOUS_BLUE;
      color: $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 1px solid $CURIOUS_BLUE;
    }
    .div-size {
      width: 500px;
    }

    &.panel-unsubscribe {
      .p-panel-content {
        min-height: auto;
      }
      .div-size {
        @media screen and (max-width: 576px) {
          min-width: 320px;
          width: auto;
        }
      }
      .panel-unsubscribe__panel {
        @media screen and (max-width: 576px) {
          min-width: 100%;
        }
        @media screen and (max-width: 480px) {
          min-width: 95%;
        }
      }
    }
  }

  &.unsubscribe-feedback {
    .unsubscribe-feedback__message {
      min-height: 110px;
      display: flex;
      align-items: center;
      .success-icon {
        color: $green;
      }
      .error-icon {
        color: $red;
      }
    }
  }
}
