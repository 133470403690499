.layout-profile {
  margin-top: 100px;
  margin-bottom: 100px;

  .edit-user-label {
    font-size: 12px;
    color: #666666;
    line-height: 1;
    position: absolute;
    pointer-events: none;
    margin-top: -1rem;
    margin-left: 5px;
  }

  .dropdown-style {
    border: 1px solid #3b97de;
    border-radius: 6px;
  }

  .p-password {
    display: contents;
  }

  .p-panel-header {
    .p-panel-title {
      line-height: 1.2;
    }
    background-color: #3b97de;
    border: 4px solid #3b97de;
    color: white;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    font-size: 18px;
  }

  .p-panel {
    width: 75%;
  }

  .p-checkbox-label {
    display: contents;
  }

  .login-input {
    margin-top: 15px;
    margin-bottom: 15px;
    .p-inputtext {
      border: 1px solid $CURIOUS_BLUE;
      border-radius: 6px;
    }
    .p-inputtext:enabled:hover {
      border: 1px solid $CURIOUS_BLUE;
    }
  }

  .p-button-icon {
    visibility: collapse;
  }

  .login-input-dropdown {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .p-checkbox-label {
    font-size: 0.9em;
    display: contents;
  }

  .p-checkbox {
    margin-right: 5px;
  }

  .login-button {
    .p-button-text {
      font-size: 15px;
      font-weight: bold;
    }
    .p-button:enabled:hover {
      background-color: $LOCHINVAR;
      border-color: $LOCHINVAR;
    }
  }
  .panel-registration {
    font-family: "Apis";

    .p-button {
      background-color: $LOCHINVAR;
      border-color: $LOCHINVAR;
      border-radius: 8px;
    }

    .p-panel-content {
      min-height: 256px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border: 1px solid $CURIOUS_BLUE;
    }

    .p-panel-titlebar {
      font-size: large;
      background-color: $CURIOUS_BLUE;
      color: $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 1px solid $CURIOUS_BLUE;
    }
    .div-size {
      width: 500px;
    }
  }

  .text-center {
    text-align: center;
  }

  .margin-bottom-10 {
    margin-bottom: 10px;
  }

  .profile-picture {
    width: 200px;
    height: 200px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 60px;
  }

  .p-inputnumber {
    display: contents;
  }

  .p-inputnumber .p-inputnumber-input {
    width: 100%;
  }

  .profile-picture:hover {
    cursor: pointer;
  }

  .custom-pencil {
    font-size: 2em;
    color: #2a918b;
    margin-left: 200px;
    margin-top: 160px;
  }

  .button-save-picture {
    margin-top: 13px;
    margin-bottom: 10px;
  }

  .button-cancel-picture {
    margin-left: 10px;
    margin-top: 13px;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .button-upload-image {
    font-size: 14px;
    padding: 0.429em 1em;
    color: white;
    background-color: #007ad9;
    border-radius: 3px;
  }

  .btn-small {
    background-color: #2a918b;
    border: 1px solid #2a918b;
    border-radius: 6px;
    font-size: 14px;
    padding: 0.429em 1em;
    color: white;
    width: auto;
  }

  .btn-small:hover {
    background-color: #2a918b;
    cursor: pointer;
    border: 1px solid #2a918b;
    border-radius: 6px;
    font-size: 14px;
    padding: 0.429em 1em;
    color: white;
    width: auto;
  }

  .avatar-editor-size {
    width: 250px;
    height: 250px;
  }
}
