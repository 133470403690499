.layout-registration {
  margin-top: 100px;
  margin-bottom: 100px;

  .p-password {
    display: contents;
  }

  .p-panel-header {
    .p-panel-title {
      line-height: 1.2;
    }
    background-color: #3b97de;
    border: 4px solid #3b97de;
    color: white;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    font-size: 18px;
  }

  .p-panel {
    width: 75%;
  }

  .p-checkbox-label {
    display: contents;
  }

  .login-input {
    margin-top: 15px;
    margin-bottom: 15px;
    .p-inputtext {
      border: 1px solid $CURIOUS_BLUE;
      border-radius: 6px;
    }
    .p-inputtext:enabled:hover {
      border: 1px solid $CURIOUS_BLUE;
    }
  }

  .policy-link {
    color: crimson;
  }

  .p-checkbox-label {
    font-size: 0.9em;
    display: contents;
  }

  .p-checkbox {
    margin-right: 5px;
  }

  .login-button {
    .p-button-text {
      font-size: 15px;
      font-weight: bold;
    }
    .p-button:enabled:hover {
      background-color: $LOCHINVAR;
      border-color: $LOCHINVAR;
    }
  }

  .panel-registration {
    font-family: "Apis";

    .p-button {
      background-color: $LOCHINVAR;
      border-color: $LOCHINVAR;
      border-radius: 8px;
    }

    .p-panel-content {
      min-height: 256px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border: 1px solid $CURIOUS_BLUE;
    }

    .p-panel-titlebar {
      font-size: large;
      background-color: $CURIOUS_BLUE;
      color: $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 1px solid $CURIOUS_BLUE;
    }
    .div-size {
      width: 500px;
    }
  }
}

.p-menu .p-submenu-header {
  padding: 0rem;
}
