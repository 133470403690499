.layout-footer {
  .opk-container {
    text-align: center;
    margin-top: 20px;
  }

  .opk-privacy-policy {
    position: relative;
    display: block;
    margin-top: 10px;
  }

  .opk-logo {
    width: 80px;
    height: 80px;
  }

  .opk-cursor-pointer {
    cursor: pointer;
  }

  .opk-twitter-logo {
    width: 70%;
    height: 70%;
    margin: 15%;
  }

  .opk-facebook-logo {
    width: 60px;
    height: 60px;
  }

  .opk-facebook-logo-image {
    width: 70%;
    height: 70%;
    margin: 15%;
  }

  .opk-store-image {
    width: 40%;
  }

  .opk-margin-right-10px {
    margin-right: 10px;
  }
}
