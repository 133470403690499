.white-space-pre-wrap {
  white-space: pre-wrap;
}

.layout-event {
  p {
    white-space: pre-wrap;
  }

  img {
    align-content: center;
    margin: 0;
    padding: 0;
  }

  .small-card {
    padding: 0;
    margin: 10px;
    .p-card-title {
      font-size: 0.9rem;
    }
    .p-card-content {
      font-size: 1rem;
    }
  }

  .p-card-title {
    font-size: 0.9rem;
    font-weight: normal;
    padding: 0;
    margin: 0;
    text-align: center;
    color: rgb(42, 145, 139);
  }

  .p-col-6-no-padding {
    padding: 0rem !important;
  }

  .col-6-no-padding {
    padding: 0.5rem !important;
  }

  .description {
    font-size: 0.9rem;
    text-align: left;
  }

  .note-fix {
    font-size: 0.7rem;
    text-align: left;
    color: #ed4f00;
    padding: 0px;
  }

  .header-card-title {
    margin-bottom: 10px;
    align-self: center;
  }

  .p-card {
    height: 100%;
  }

  .title {
    color: rgb(42, 145, 139);
    font-size: 25px;
    float: left;
    text-align: center;
  }

  .register-button {
    background-color: rgb(42, 145, 139);
    border-color: rgb(42, 145, 139);
  }

  .register-button:hover {
    background-color: rgb(105, 179, 175);
    border-color: rgb(105, 179, 175);
  }

  .p-card-content {
    font-size: 1rem;
    font-weight: 700;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .card-with-padding {
    padding: 1rem !important;
    .p-card-content {
      font-size: 1.1rem;
    }
  }

  .card-content-font-size {
    .p-card-content {
      font-size: 1.1rem;
    }
  }

  .card-title {
    font-size: 0.9rem;
    font-weight: normal;
    padding: 0;
    margin: 0;
    text-align: center;
    color: #2a918b;
  }
  .card-title-nordic {
    font-size: 0.9rem;
    font-weight: normal;
    padding: 0;
    margin: 0;
    text-align: center;
    color: white;
  }
  .card-text {
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
    text-align: center;
    color: black;
  }

  .card-orange-text {
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
    color: #ed4f00;
  }

  .button-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .card-body {
    padding: 0;
  }

  .p-card {
    padding: 0;
  }

  .p-card-body {
    padding: 3px;
  }

  .card-img-event {
    margin-bottom: 10px;
  }

  .btn-cursor {
    cursor: pointer;
    border-color: white;
  }

  .text-left {
    text-align: left;
  }

  .button-sign-out {
    background-color: #ed4f00;
    border-color: #ed4f00;
  }

  .button-sign-out:hover {
    background-color: #ff7a38;
    border-color: #ff7a38;
  }

  .text-center {
    text-align: center;
  }
}

.gmap {
  width: 100%;
  min-height: 520px;
  margin-top: 10px;
}

.p-toolbar-group-right {
  .p-button {
    margin-left: 10px;
  }
}

.p-confirm-dialog-accept {
  background-color: #2a918b;
  border-color: #2a918b;
  color: white;
}

.p-confirm-dialog-accept:hover {
  background-color: #2a918b !important;
  border-color: #2a918b !important;
}

.p-confirm-dialog-reject {
  background-color: #ed4f00 !important;
  border-color: #ed4f00 !important;
  color: white !important;
}

.ok-button {
  background-color: #2a918b !important;
  border-color: #2a918b !important;
}

.reject-button {
  visibility: hidden;
}
