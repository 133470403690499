.layout-message {
  .p-toast {
    width: 98%;
    .p-toast-message {
      font-family: "Apis" !important;
      .p-toast-message-content {
        .p-toast-message-text {
          font-weight: bold;
          white-space: pre-wrap;
        }
      }
    }
    .p-toast-message-icon {
      visibility: hidden;
    }
  }
}
